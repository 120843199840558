<template>
    <div v-loading="isLoading" element-loading-text="Loading..." element-loading-spinner="el-icon-loading" class="iframe-container">
    <iframe 
      :src="getSourceLink"
      width="100%"
      height="700"
      @load="iframeLoaded"
      style="border: none;"
    ></iframe>
  </div>
    <!-- <iframe :src="getSourceLink" width="100%" height="700">
</iframe> -->
</template>
<script lang="ts">
import { mapGetters } from "vuex";
export default {
    name: "External Link",
    data(){
        return{
            menu:'',
            src:'',
            isLoading: true, 
            currentId : '',
        }
    },
computed :{
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getUserMenu",
      "getActiveWorkspace",
      "getUserType",
      "getPermissionList",
      "getPermissions",
    ]),
    getSourceLink() {
        console.log("thissss",this.src)
        return this.src;
    }
},
async mounted() {
    this.src = ''
    this.getMenuList();
},
watch:{
    "$route.params.externalLinkId"(){
        if(this.currentId != this.$route.params.externalLinkId){
        this.getMenuList();
        }
    }

},
methods:{
    async getMenuList() {
      if (this.getMenu) {
        this.src =  ''
        this.getMenu.menu_list.forEach((e) => {
            if (e && e.children && e.children.length) {
              e.children.forEach((f) => {
                if(f && f.children && f.children.length){
                    f.children.forEach((g) => {
                        if (g._id ===this.$route.params.externalLinkId) {
                            this.currentId =this.$route.params.externalLinkId
                            this.src = g.linkData
                        }
                    })
                }
                else if (f._id ===this.$route.params.externalLinkId) {
                    this.currentId =this.$route.params.externalLinkId
                    this.src = f.linkData
                }
              })
            }
            else if(e.feature ==="EXTERNAL_LINK" ){
                if(this.$route.params.externalLinkId == e._id){
                    this.currentId =this.$route.params.externalLinkId
                this.src = e.linkData
                }
            }
          });

        }
    },
    iframeLoaded() {
      this.isLoading = false; 
    }
}
}
</script>